import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl";
import { Helmet } from 'react-helmet'

import styled from 'styled-components';

const H1 = styled.h1`
    font-size: 30px;
}`;

const TextJustified = styled.div`
    text-align: justify;
}`;

const H2 = styled.h2`
    font-size: 25px;
    width: 100%;
    margin-top: 10px;
}`;

const H3 = styled.h3`
    font-size: 20px;
    width: 100%;
    margin-top: 5px;
}`;

const P = styled.p`
    margin-top: 5px;
    color: black;
    font-size: inherit;
}`;

const CookiesPolicy = ({ data }) =>  ({
 
    render(){
            return(
                <Layout>
                    <SEO title="Privacy Policy" />
                    <section>

                    <div className="site-breadcrumb">
                        <div className="container">		
                        </div>
                    </div>

                    <section className="page-section blog-page">
                    <div className="container">
                        <TextJustified className="row" id="privacy-policy">
                            <H1><strong>POLITICA DE COOKIES</strong></H1>
                            <P>Utilizamos cookies con el objetivo de prestar un mejor servicio y proporcionarte una mejor experiencia en tu navegación. Queremos informarte de manera clara y precisa sobre las cookies que utilizamos, detallando a continuación, qué es una cookie, para qué sirve, qué tipos de cookies utilizamos, cuáles son sus finalidades y cómo puedes configurarlas o deshabilitarlas si así lo deseas.</P>
                            <H2>¿Qué son las cookies?</H2>
                            <P>Una "cookie" es un pequeño archivo que se almacena en el ordenador del usuario, tablet, smartphone o cualquier otro dispositivo con información sobre la navegación.</P>

                            <P>Las cookies son esenciales para el funcionamiento de Internet, aportando innumerables ventajas, facilitándote la navegación y usabilidad de nuestra web.En ningún caso las cookies podrían dañar tu equipo. Por contra, el que estén activas nos ayuda a conocer el rendimiento de la página web, qué debemos modificar y los problemas que pueden surgir.</P>

                            <H2>¿Qué tipos de cookies utilizamos?</H2>

                            <P>Según la entidad que las gestiona</P>

                            <H3>Cookies de terceros</H3>
                            <P>Son aquellas que se envían a tu equipo desde un equipo o dominio que no es gestionado por nosotros, sino por otra entidad colaboradora. Como por ejemplo, las usadas por redes sociales, o por contenido externo como Google Maps.</P>

                            <P>Según el plazo de tiempo que permanecen activadas</P>

                            <H3>Cookies de sesión</H3>
                            <P>Son cookies temporales que permanecen en el archivo de cookies de tu navegador hasta que abandonas la página web, por lo que ninguna queda registrada en el disco duro de tu ordenador. La información obtenida por medio de estas cookies sirve para analizar pautas de tráfico en la web. A la larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitar su uso.</P>

                            <P>Según su finalidad</P>

                            <H3>Cookies técnicas</H3>
                            <P>Son aquellas necesarias para la navegación y el buen funcionamiento de nuestra página web. Permiten, por ejemplo, controlar el tráfico y la comunicación de datos, acceder a partes de acceso restringido, realizar el proceso de compra de un pedido, utilizar elementos de seguridad, almacenar contenidos para poder difundir vídeos o compartir contenidos a través de redes sociales.</P>

                            <H3>Cookies de análisis</H3>
                            <P>Son aquellas que nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios de los servicios prestados. Para ello, se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos y servicios que ofrecemos.</P>

                            <H2>Detalle de nuestras cookies</H2>
                            <H3>Application Insights</H3>
                            <P>Este sitio web utiliza Application Insights, un servicio de análisis web proporcionado por Microsoft, Inc. ("Microsoft"). Application Insights utiliza "cookies" para ayudar al sitio web a analizar cómo los usuarios utilizan el sitio. La información generada por la cookie acerca de su uso del sitio web (incluyendo su dirección IP) será transmitida y almacenada por Microsoft en servidores de Europa. Microsoft utilizará esta información con el fin de evaluar su uso del sitio web, recopilar informes sobre la actividad del sitio web para los operadores del mismo y proporcionar otros servicios relacionados con la actividad del sitio web y el uso de Internet. Microsoft también puede transferir esta información a terceros cuando así se lo requiera la ley, o cuando dichos terceros procesen la información en nombre de Microsoft.</P>
                            <H3>Cookies utilizadas</H3>
                            <Helmet>
                                <script 
                                    id="CookieDeclaration" 
                                    src="https://consent.cookiebot.com/671519c2-4d46-4916-ad5e-f3b9fc881f8e/cd.js" 
                                    type="text/javascript" 
                                    async
                                />
                            </Helmet>

                            <P>Si tienes cualquier duda sobre el uso que hacemos de las cookies o sobre la configuración o revocación del consentimiento, puedes ponerte en contacto con nosotros enviándonos un correo electrónico a info@visaverde.com</P>
                        </TextJustified>
                    </div>
                    </section>
                </section>
                </Layout>
            )
        }

    })

export default injectIntl(CookiesPolicy)
export const query = graphql`
query {
    placeholderImage: file(relativePath: { eq: "page-top-bg.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
            }
        }
    }
}
`